.player-page {
  max-width: 2000px;
  margin: 0 auto;
}

.player-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.left_container_player {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.player_name {
  display: flex;
  flex-direction: column;
}

.player-avatar {
  width: 100px;
  height: 100px;
}

.player-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 30px;
}

.player-info-item {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
}

.label_player {
  width: 100px; 
  font-weight: bold !important;
  font-size: 1.1em;
}

.back-button {
  align-self: flex-end;
}

.stat-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.player-details {
  margin-top: 20px;
  padding-left: 30px;
}

.custom-ag-grid {
  width: 100%;
}

.player-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
  width: calc(100% - 40px);
  padding: 20px;
  margin-top: 10px;
}

.stat-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0px;
  border-color: #bdbcbc;
}

.grey-background {
  background-color: #e0e0e0; /* Light grey background */
}

.stat-box:first-child {
  grid-column-start: 1;
}


.loader-item {
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
}

.player-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.player-details-item {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
}

.label_player_1 {
  width: 250px; /* Adjust this width to ensure all labels have the same length */
  font-weight: bold !important;
  text-align: end;
  padding-right: 30px;
  padding-bottom: 5px;
}

.select_container_items {
  padding-top: 50px;
}

.ag-header-cell-label {
  justify-content: center !important;
}

.chart_loading_container {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 0px;
}

.chart_tab_container {
  height: calc(100vh - 291px) !important;
  height: calc(100dvh - 291px) !important;
}

.chart_tab_container_game {
  height: calc(100vh - 243px) !important;
  height: calc(100dvh - 243px) !important;
}

.mobile_grid {
  height: calc(100vh - 171px) !important;
  height: calc(100dvh - 171px) !important
}

.main_grid_override {
  height: calc(100vh - 50px) !important;
  height: calc(100dvh - 50px) !important
}

.mobile_grid_player {
  height: calc(100vh - 191px) !important;
  height: calc(100dvh - 191px) !important
}

.mobile_grid_main {
  height: calc(100vh - 50px) !important;
  height: calc(100dvh - 50px) !important
}

.mobile_grid .ag-header-cell-text {
  font-size: 12px !important;
}

.non_mobile_grid {
  height: calc(100vh - 238px) !important;
  height: calc(100dvh - 238px) !important;
}

.player_container {
  height: 100%;
}

.main_player_container {
  padding-top: 15px;
  height: calc(100% - 15px);
}




@media (max-width: 999px) {
  .chart_loading_container {
    top: 1px;
  }
  .player-details-item {
    gap: 5px;
  }

  .player-details {
    padding-left: 0px;
  }
  .player_header {
    font-size: 28px !important;
  }
  .main_player_container {
    padding-top: 10px;
    height: calc(100% - 20px);
  }
  .chart_tab_container {
    height: calc(100vh - 263px) !important;
    height: calc(100dvh - 263px) !important;
  }
  .chart_tab_container_game {
    height: calc(100vh - 215px) !important;
    height: calc(100dvh - 215px) !important;
  }
  .select_container_items {
    padding-top: 10px;
  }

  .loader-item {
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
  }

  .player-header {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  .left_container_player {
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .player_name {
    font-size: 1.2em; /* Smaller text at the top */
    margin-right: auto;
    margin-left: auto;
    position: relative;
    /* left: 10px; */
  }

  .player-avatar {
    width: 80px; /* Smaller avatar */
    height: 80px; /* Smaller avatar */
  }

  .player-info {
    align-items: center;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  .player-info-item {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }

  .label_player, .label_player_1 {
    width: auto;
    text-align: left;
    padding-right: 0;
    font-size: 18px !important; 
    width: unset;
  }

  .label_player_1_item {
    font-size: 16px !important;
  }

  .stat-box {
    padding: 4px;
    font-size: 0.6em; 
  }

  .custom-ag-grid {
    height: auto;
    flex: 1 !important;
  }

  .player-stats {
    grid-template-columns: repeat(3, 1fr); /* Change to 3 columns on mobile */
  }
}

@media (max-width: 600px) {
  .player_autocomplete .MuiAutocomplete-endAdornment{
   display: none !important;
  }

  .player_select .MuiSvgIcon-root {
    display: none !important;
  }

  .player_select_stat {
    width: 75px !important;
    font-size: 14px !important;
  }

  .player_select_chart {
    width: 90px !important;
    font-size: 14px !important;
  }

  .player_autocomplete {
    width: 200px !important;
    font-size: 14px !important;
  }
  .player_autocomplete .MuiInputBase-root {
  padding-right: 0px !important;
  padding-left: 0px !important;
  font-size: 14px !important;
 }

 .player_autocomplete .MuiInputBase-input {
  font-size: 14px !important;
 }

  .player_select_chart .MuiSelect-select {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .player_select_stat .MuiSelect-select {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media(max-width: 400px) {
  .player_autocomplete {
    width: 175px !important;
  }
}

@media(max-width: 360px) {
  .player_autocomplete {
    width: 150px !important;
  }
}