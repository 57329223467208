.player-cell-item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.player-cell-button {
    width: 100%;
}

@media screen and (max-width: 999px) {
    .player-cell-button {
        font-size: 12px !important;
    }
    
}
