.top_page_banner {
  height: 80px;
  width: 100vw;
  background-color: #1A283A;
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort_button {
  width: 40px !important;
}

.main_logo {
  height: 40px;
  position: relative;
  cursor: pointer;
  top: -2px
}

.main_page_container {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 300px 1fr; /* First column is 300px, second column takes the remaining space */
}

.back-button {
  position: relative;
}

.table_padding {
  padding-top: 80px
}

.table_padding_2 {
  padding-top: 80px
}

.side_panel {
  width: 300px;
  background-color: #1A283A;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer-tabs .Mui-selected {
  color: white !important;
}

.tabs_selector .Mui-selected {
  color: white !important;
}

.tabs_selector .Mui-disabled {
  color: grey !important;
}

.coach_text {
  font-size: 20px;
}

.info_page {
  position: absolute;
  top: 80px;
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  width: calc(100vw - 310px);
  width: calc(100dvw - 310px);
  left: 300px;
  z-index: 999;
  background-color: white;
  padding: 10px;
}

.stat-table-container {
  width: 100%;
  overflow-x: auto;
  margin-left: 10px;
  height: calc(100vh - 120px);
  height: calc(100dvh - 120px);
  overflow-y: auto;
}

.stat-table {
  width: calc(100% - 10px);
  border-collapse: collapse;
  text-align: left;
  background-color: #f9f9f9;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.stat-table thead th { position: sticky; top: 0; z-index: 1; }

.stat-table-container th, .stat-table-container td {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
}

.stat-table th {
  background-color: #222;
  color: white;
  text-transform: uppercase;
}

.stat-table tbody tr:hover {
  background-color: #f1f1f1;
}

.stat-table tbody tr:last-child td {
  border-bottom: none;
}
 
@media screen and (max-width: 999px) {
  .stat-table-container {
    height: calc(100vh - 90px);
    height: calc(100dvh - 90px);
  }

  .info_page {
    position: absolute;
    top: 50px;
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
    width: calc(100vw - 10px);
    width: calc(100dvw - 10px);
    left: 0px;
  }

  .stat-table th, .stat-table td {
   font-size: 12px !important;
  }

  .coach_text {
    font-size: 15px;
    min-width: 108px;
  }
  .main_logo {
    height: 22px;
  }
  
  .sort_button {
    width: 25px !important;
  }
  .main_page_container {
    grid-template-columns: 1fr;
  }

  .top_page_banner {
    height: 50px;
  }
  .table_padding {
    padding-top: 40px
  }


}

@media screen and (max-width: 450px) {
  .coach_text {
    font-size: 12px;
    min-width: unset;
  }
  .main_logo {
    height: 20px;
  }
}

@media screen and (max-width: 395px) {
  .coach_text {
    font-size: 9px;
    position: relative;
    min-width: unset;
  }
  .main_logo {
    height: 16px;
  }

}