

/* Ensure the header text is larger and not cut off */
.ag-theme-alpine .ag-header-cell {
  font-size: 1.2rem; /* Bigger text for header row */
}

.loader-item {
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
}

.table-container {
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  overflow-y: auto;
  position: relative;
}
/* Co   lor the sticky header cells light grey */
.ag-theme-alpine .ag-pinned-left-header,
.ag-theme-alpine .ag-pinned-left-cols-container {
  background-color: #f0f0f0; /* Light grey color for sticky columns */
}

/* Prevent horizontal scroll on pinned columns */
.custom-ag-grid .ag-pinned-left-cols-container {
  overflow-x: hidden !important;
}

/* Ensure horizontal scroll on non-pinned columns */
.custom-ag-grid .ag-body-viewport {
  overflow-x: auto !important;
}

/* Color the pinned columns' body elements light grey */
.custom-ag-grid .ag-pinned-left-cols-container .ag-cell {
  background-color: #f0f0f0;
}

.custom-ag-grid {
  flex: 1 !important;
}

.ag-horizontal-left-spacer {
  overflow: hidden;
  height: 0px;
}

.mobile_grid .ag-header-cell {
  font-size: 13px !important;
  padding: 0px !important
}

.mobile_grid .ag-cell {
  font-size: 11px !important;
   padding: 4px !important
}

.ag-header-cell-label {
  justify-content: center !important;
}

.main_stats_loading_container {
  width: calc(100vw - 300px);
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 80px;
}

@media screen and (max-width: 999px) {
  .main_stats_loading_container {
    width: calc(100vw);
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 50px;
  }
  .loader-item {
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
  }
  
  .table-container {
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
  }
  
}