.teams_horizontal {
  display: flex;
  border: 1px solid black;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;
  margin-top: 10px;
}

.teams_horizontal .MuiTabScrollButton-root {
  display: none;
}

.teams-page {
    overflow-y: auto;
    height: calc(100vh -  80px);
    height: calc(100dvh -  80px);
    display: flex;
    flex-direction: column;
  }

  .teams-page_player {
    overflow-y: auto;
    height: calc(100vh - 148px);
    height: calc(100dvh -  148px);
  }

  .guardian_container {
    overflow-y: auto;
  }

  .small_player_header {
    font-weight: bold;
    width: 126px;
    display: inline-block;
    text-align: right;
  }

  .teams_player_loading_container {
    width: calc(100vw - 300px);
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 80px;
  }

  .teams_loading_container {
    width: calc(100vw - 300px);
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 80px;
  }

  .teams_title{
    font-family: JostRegular;
    font-size: 24px;
  }


  .teams_button {
    width: 150px;
    margin: 1px;
    font-family: KhandSemiBold;
    letter-spacing: 2px;
    font-size: 18px;
  }

  .search-sort-bar {
    min-height: fit-content;
    padding: 20px;
    padding-bottom: 5px;
    overflow-x: hidden;
    background-color: white;
    z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding-top: 0px;
  }

  .team_button {
    font-size: 20px !important;
  }

  .players-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 20px;
  }
  
  .player-card-teams {
    padding: 20px;
    border-radius: 10px !important;
    cursor: pointer;
    transition: all .15s linear !important;
    transform: scale(1);
  }

  .player-card-teams:hover {
   transform: scale(1.04);
  }
  
  .player-avatar {
    width: 100px !important;
    height: 100px !important;
  }
  
  .player-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .flex_chip_1 {
    flex: 0 0 calc(30% - 6px);
    width: calc(30% - 6px);
    margin: 3px;
    border-radius: 5px !important;
    font-size: 18px !important;
  }

  .flex_chip_2 {
    flex: 0 0 calc(70% - 6px);
    width: calc(70% - 6px);
    margin: 3px;
    border-radius: 5px !important;
    font-size: 18px !important;
  }

  .flex_chip {
    flex: 0 0 calc(50% - 6px);
    width: calc(50% - 6px);
    margin: 3px;
    border-radius: 5px !important;
    font-size: 18px !important;
  }
  
 .player-info-teams {
    display: flex;
    flex-wrap: wrap;
 }
 .mid_bar_item {
  color: white;
  margin-bottom: 10px;
  margin-top: 20px;
  height: 40px;
  width: 100%;
  background-color: #FF0000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
 }  

 .right_chips {
  width: calc(100% - 100px);
 }

 .team_code_text {
  font-size: 24px;
  position: relative;
  left: -20px;
 }

 .text_team {
  min-width: 285px;
 }

 @media screen and (max-width: 1100px) {
  .team_code_text {
  font-size: 20px;
  }
  .text_team {
    min-width: 250px;
   }

   .team_button {
    font-size: 16px !important;
   }
 }

  
 @media screen and (max-width: 999px) {

  .search_bar_main {
    position: relative;
    top: 5px;
   }

  .teams_player_loading_container {
    width: 100vw;
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
    top: 50px;
  }

  .guardian_text {
    font-size: 17px !important;
  }
  
  .teams-page_player {
    overflow-y: auto;
    height: calc(100vh - 41px);
    height: calc(100dvh -  41px);
  }
  .player_account_text {
    font-size: 20px !important;
  }

  .team_code_text {
    font-size: 20px;
    position: relative;
    left: 0px;
  }
  .team_button {
    font-size: 14px !important;
  }
  .player-card-teams {
    transform: none !important;
  }

  .teams_title {
    font-size: 20px;
  }
  .player-card-teams {
    padding: 12px !important;
  }
  .right_chips {
    width: calc(100% - 40px);
   }
  .player-avatar {
    width: 60px !important;
    height: 60px !important;
    position: relative;
    top: 10px;
  }


  
  .players-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 5px;
  }

  .mid_bar_item {
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 20px;
  }
  .flex_chip {
    font-size: 10px !important;
    height: 20px !important;
  }

  .flex_chip_1 {
    font-size: 10px !important;
    height: 20px !important;
  }

  .flex_chip_2 {
    font-size: 10px !important;
    height: 20px !important;
  }

  .search-sort-bar {
    padding: 0px;
    overflow: hidden;
  }

  .teams_horizontal {
    margin-right: 20px;
  }
  

  .teams-page {
    padding: 0px;
    overflow-y: auto;
    height: 100%;
  }

  .teams_loading_container {
    height: calc(100vh - 50px);
    height: calc(100dvh - 50px);
    top: 50px;
    width: 100vw;
  }

  
}

@media screen and (max-width: 400px) {
  .team_button {
    font-size: 12px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    max-width: 100px !important;
  }
}