.login_container {
    height: calc(100dvh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f8fa;
    width: 100vw;
}

@media screen and (max-width: 768px) {
    .login_header_item {
        font-size: 26px !important;
    }
    
}