
body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}


code {
  font-family: 'Jost', sans-serif;
}

::-webkit-scrollbar {
  width: 14px; /* Make the scrollbar wider */
  height: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: #555; /* Darker grey color */
}
::-webkit-scrollbar-track {
  background: #c9c9c9; /* Light grey background */
}

.app_style_wrapper {
  overflow-x: hidden;
}

.app_style_wrapper_teams {
  overflow-y: hidden;
}


/* JOST FONT */
@font-face {
  font-family: "JostBlack";
  src: local("JostBlack"),
  url("./fonts/Jost-Black.ttf") format("truetype");
}

@font-face {
  font-family: "JostBlackItalic";
  src: local("JostBlackItalic"),
  url("./fonts/Jost-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "JostBold";
  src: local("JostBold"),
  url("./fonts/Jost-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "JostBoldItalic";
  src: local("JostBoldItalic"),
  url("./fonts/Jost-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "JostExtraBold";
  src: local("JostExtraBold"),
  url("./fonts/Jost-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "JostExtraBoldItalic";
  src: local("JostExtraBoldItalic"),
  url("./fonts/Jost-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "JostExtraLight";
  src: local("JostExtraLight"),
  url("./fonts/Jost-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "JostExtraLightItalic";
  src: local("JostExtraLightItalic"),
  url("./fonts/Jost-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "JostItalic";
  src: local("JostItalic"),
  url("./fonts/Jost-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "JostLight";
  src: local("JostLight"),
  url("./fonts/Jost-Light.ttf") format("truetype");
}

@font-face {
  font-family: "JostLightItalic";
  src: local("JostLightItalic"),
  url("./fonts/Jost-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "JostMedium";
  src: local("JostMedium"),
  url("./fonts/Jost-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "JostMediumItalic";
  src: local("JostMediumItalic"),
  url("./fonts/Jost-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "JostRegular";
  src: local("JostRegular"),
  url("./fonts/Jost-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "JostSemiBold";
  src: local("JostSemiBold"),
  url("./fonts/Jost-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "JostSemiBoldItalic";
  src: local("JostSemiBoldItalic"),
  url("./fonts/Jost-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "JostThin";
  src: local("JostThin"),
  url("./fonts/Jost-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "JostThinItalic";
  src: local("JostThinItalic"),
  url("./fonts/Jost-ThinItalic.ttf") format("truetype");
}

/* KHAND FONT */
@font-face {
  font-family: "KhandBold";
  src: local("KhandBold"),
  url("./fonts/Khand-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "KhandLight";
  src: local("KhandLight"),
  url("./fonts/Khand-Light.ttf") format("truetype");
}

@font-face {
  font-family: "KhandMedium";
  src: local("KhandMedium"),
  url("./fonts/Khand-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "KhandRegular";
  src: local("KhandRegular"),
  url("./fonts/Khand-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "KhandSemiBold";
  src: local("KhandSemiBold"),
  url("./fonts/Khand-SemiBold.ttf") format("truetype");
}

